
































































































import { defineComponent, ref, Ref } from '@vue/composition-api'
import * as t from 'io-ts'
import { useTask } from 'vue-concurrency'
import { getWebinarsApi, attendWebinar } from './api/fbfunctions'
import { isLeft } from 'fp-ts/Either'
import { PathReporter } from 'io-ts/PathReporter'

const Webinar = t.type({
  id: t.string,
  name: t.string
})

const AccessGranted = t.type({
  type: t.literal('granted'),
  href: t.string
})

const AccessDenied = t.type({
  type: t.literal('denied'),
  missingRolle: t.string,
  kontakt: t.string
})

const WrongCredentials = t.type({
  type: t.literal('wrongCredentials'),
  kontakt: t.string
})

const attendWebinarResponses = t.union([AccessGranted, AccessDenied, WrongCredentials])

export default defineComponent({
  setup () {
    const help = ref(false)
    const isPwd = ref(true)
    const selectedWebinar: Ref<t.TypeOf<typeof Webinar>|false> = ref(false)
    const user = ref()
    const password = ref()
    const passwortVergessen = 'https://www.vereinonline.org/BeachL_eV/?action=start_passwortvergessen' // TODO Remote Config
    const getWebinars = useTask(function * () {
      // yield timeout(1000)
      // selectedWebinar.value = { name: 'MV', id: '12323' }
      // return [{ name: 'MV', id: '12323' }, { name: 'VS', id: 'aasdf' }]
      const result = yield getWebinarsApi()
      const decoded = t.array(Webinar).decode(result.data)
      if (isLeft(decoded)) throw new Error(`Error Loading Webinars: Wrong data format provided from server: ${PathReporter.report(decoded)}`)
      const data = decoded.right
      if (data.length > 0) {
        selectedWebinar.value = data[0]
      }
      return data
    })
    const onSubmit = useTask(function * () {
      // yield timeout(1000)
      // throw new Error('asdf')
      // const data = { type: 'granted', href: 'https://beachl.de' }
      // const data = { type: 'denied', kontakt: 'tel:023991234', missingRolle: 'Mitglied' }
      // const data = { type: 'wrongCredentials', kontakt: 'tel:023991234' }
      if (!selectedWebinar.value) throw new Error('Webinar isn\'t selected')
      const result = yield attendWebinar(selectedWebinar.value.id, user.value, password.value)
      const decoded = attendWebinarResponses.decode(result.data)
      if (isLeft(decoded)) throw new Error(`Error Attending Webinars: Wrong data format provided from server ${selectedWebinar.value.id} ${user.value}: ${PathReporter.report(decoded)}`)
      const data = decoded.right
      if (data.type === 'granted') {
        window.location.href = data.href
      }
      if (data.type === 'denied' || data.type === 'wrongCredentials') {
        const regex = /^.*:(.*)$/gm
        let m
        if ((m = regex.exec(data.kontakt)) !== null) {
          if (data.type === 'denied') {
            return { type: data.type, href: data.kontakt, kontakt: m[1], missingRolle: data.missingRolle }
          } else {
            return { type: data.type, href: data.kontakt, kontakt: m[1] }
          }
        }
      }
      return data
    })

    getWebinars.perform()

    return {
      isPwd,
      getWebinars,
      selectedWebinar,
      user,
      password,
      passwortVergessen,
      onSubmit,
      help
    }
  }
})
