import firebase from 'firebase/app'
const firebaseConfig = {
  apiKey: 'AIzaSyCcMBt8pzHrv-kTm-_2lZ7m3GpJhMVQ1dE',
  authDomain: 'beachl-webinar.firebaseapp.com',
  databaseURL: 'https://beachl-webinar-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'beachl-webinar',
  storageBucket: 'beachl-webinar.appspot.com',
  messagingSenderId: '402808930031',
  appId: '1:402808930031:web:6ed72e97469f0496faa51a'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
