import Vue from 'vue'

import './styles/quasar.scss'
import lang from 'quasar/lang/de.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';

Vue.use(Quasar, {
  config: {},
  plugins: {
  },
  lang: lang
})
